import { useStaticQuery, graphql } from 'gatsby';

// eslint-disable-next-line import/prefer-default-export
export const getPrivacyPolicy = () =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useStaticQuery(graphql`
    query {
      allWordpressPage(filter: { id: { eq: "80686c77-c136-583a-b7ad-342f2db3618b" } }) {
        edges {
          node {
            title
            slug
            content
          }
        }
      }
    }
  `);
