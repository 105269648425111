import Styled from 'styled-components';

import { breakpoints } from '../../styles/variables';

const ContainerWrapper = Styled.section`
  display: flex;
  flex-direction: ${({ reverseMobile }) => (reverseMobile ? 'column-reverse' : 'column')};
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 192rem;
  ${({ fullHeight }) => fullHeight && 'height: 100vh'};
  margin: auto;
  flex-wrap: wrap;
  padding: ${({ padding }) => (padding ? '2rem' : 0)};

  @media ${breakpoints.tabletPortait} {
    padding: ${({ padding }) => (padding ? '5rem 2rem' : 0)};
    ${({ rowTablet }) => rowTablet && 'flex-direction: row'};
    ${({ justifyCenterTablet }) => justifyCenterTablet && 'justify-content: center;'};
  }

  @media ${breakpoints.tabletLandscape} {
    flex-direction: ${({ column, mirrored }) =>
      // eslint-disable-next-line no-nested-ternary
      column ? 'column' : mirrored ? 'row-reverse' : 'row'};
      ${({ flexStart }) => flexStart && 'align-items: flex-start;'};
  }
`;

export default ContainerWrapper;
