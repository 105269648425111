import React from 'react';
import PropTypes from 'prop-types';

import ContainerWrapper from './Container.style';

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  mirrored: PropTypes.bool,
  padding: PropTypes.bool,
  reverseMobile: PropTypes.bool,
  column: PropTypes.bool,
  fullHeight: PropTypes.bool,
  flexStart: PropTypes.bool,
  rowTablet: PropTypes.bool,
  justifyCenterTablet: PropTypes.bool,
  className: PropTypes.string,
};

const defaultProps = {
  mirrored: false,
  padding: false,
  reverseMobile: false,
  column: false,
  fullHeight: false,
  flexStart: false,
  rowTablet: false,
  justifyCenterTablet: false,
  className: 'Container',
};

const Container = ({
  mirrored,
  children,
  fullHeight,
  padding,
  className,
  reverseMobile,
  column,
  flexStart,
  rowTablet,
  justifyCenterTablet,
}) => (
  <ContainerWrapper
    mirrored={mirrored}
    fullHeight={fullHeight}
    padding={padding}
    reverseMobile={reverseMobile}
    column={column}
    flexStart={flexStart}
    rowTablet={rowTablet}
    justifyCenterTablet={justifyCenterTablet}
    className={className}
  >
    {children}
  </ContainerWrapper>
);

Container.propTypes = propTypes;
Container.defaultProps = defaultProps;

export default Container;
