import Styled from 'styled-components';

import Container from '../../components/Container/Container';
import { H1 } from '../../styles/commonStyles';
import { breakpoints } from '../../styles/variables';

export const StyledH1 = Styled(H1)`
  padding-top: 10rem;
`;

export const ContainerWrapper = Styled(Container)`
  width: 90%;

  @media ${breakpoints.tabletLandscape} {
    width: 60%;
  }
`;
