import React from 'react';

import { getPrivacyPolicy } from './privacyPolicy.query';

import { Paragraph } from '../../styles/commonStyles';
import { colors } from '../../styles/variables';

import { ContainerWrapper, StyledH1 } from './privacyPolicy.style';

const PrivacyPolicy = () => {
  const data = getPrivacyPolicy();

  const { title, content } = data.allWordpressPage.edges[0].node;

  return (
    <ContainerWrapper padding>
      <StyledH1 color={colors.darkShade}>{title}</StyledH1>
      <Paragraph dangerouslySetInnerHTML={{ __html: content }} />
    </ContainerWrapper>
  );
};

export default PrivacyPolicy;
